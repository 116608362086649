import { Box, Button, Card, CircularProgress, Container, IconButton, TextField, Tooltip } from '@material-ui/core';
import { Close, PanTool, PlayArrow, Security, SwapHoriz } from '@material-ui/icons';

import React, { useState } from 'react';
import classNames from 'classnames';

const PlayingCard = ({ name, id, onPlay, onDiscard, onSwap, onDefend, onPick, showPlay, showDiscard, showSwap, showDefend, showPick, selected }) => {
  return (
    <Box m={1} className={classNames({ 'card-selected': selected })}>
      <Card>
        <div style={{ position: 'relative' }} className="card-wrapper">
          <img src={`/static/${name}.png`} width={150} className="card-image" />
          <div className="card-button-area">
            {showPlay && <Tooltip title="Play the card"><IconButton onClick={() => onPlay(id)} color="secondary"><PlayArrow htmlColor="#fff" /></IconButton></Tooltip>}
            {showDiscard && <Tooltip title="Discard the card"><IconButton onClick={() => onDiscard(id)} color="secondary"><Close htmlColor="#fff" /></IconButton></Tooltip>}
            {showSwap && <Tooltip title="Swap this card"><IconButton onClick={() => onSwap(id)} color="secondary"><SwapHoriz htmlColor="#fff" /></IconButton></Tooltip>}
            {showDefend && <Tooltip title="Defend myself"><IconButton onClick={() => onDefend(id)} color="secondary"><Security htmlColor="#fff" /></IconButton></Tooltip>}
            {showPick && <Tooltip title="Take this card"><IconButton onClick={() => onPick(id)} color="secondary"><PanTool htmlColor="#fff" /></IconButton></Tooltip>}
          </div>
        </div>
      </Card>
    </Box>
  )
};

export default PlayingCard;
