import { Box, Tooltip } from '@material-ui/core';
import { Person, MeetingRoom, Cancel } from '@material-ui/icons';

import React from 'react';
import { useMediaQuery } from 'react-responsive'

const getTop = (x0, y0, width, height, radius, angle) => {
  return `${y0 + Math.sin(angle) * radius - height / 2}px`;
};

const getLeft = (x0, y0, width, height, radius, angle) => {
  return `${x0 + Math.cos(angle) * radius - width / 2}px`;
};

const getAngle = (myIndex, itemIndex, totalCount) => {
  return (itemIndex - myIndex) / totalCount * 2 * Math.PI + Math.PI / 2;
};

const MOBILE_SETUP = {
  X0: 120,
  Y0: 120,
  RADIUS: 100,
  WIDTH: 30,
  HEIGHT: 30,
  AREA_SIZE: 240,
};

const DESKTOP_SETUP = {
  X0: 250,
  Y0: 250,
  RADIUS: 200,
  WIDTH: 50,
  HEIGHT: 50,
  AREA_SIZE: 500,
};

const PlayerArea = ({ places = [], myPlayerId, activePlayerId, interactivePlayerId, onClickPlace }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const myIndex = places.findIndex(place => place.playerId === myPlayerId);
  const { X0, Y0, RADIUS, WIDTH, HEIGHT, AREA_SIZE } = isTabletOrMobile ? MOBILE_SETUP : DESKTOP_SETUP;
  return (
    <Box m={1}>
      <div style={{ width: `${AREA_SIZE}px`, height: `${AREA_SIZE}px`, position: 'relative' }}>
        {places.map((place, index) => {
          const angle = getAngle(myIndex, index, places.length);
          const top = getTop(X0, Y0, WIDTH, HEIGHT, RADIUS, angle);
          const left = getLeft(X0, Y0, WIDTH, HEIGHT, RADIUS, angle);
          const border = place.quarantine ? '2px solid white' : undefined;
          let color = '#fff';
          if (activePlayerId === place.playerId) {
            color = 'red';
          }
          if (interactivePlayerId === place.playerId && activePlayerId !== interactivePlayerId) {
            color = 'green';
          }
          
          return (
            <Tooltip title={place.playerName} open={place.playerName ? true : false}>
              <div className="player-place" style={{ top, left, border, width: `${WIDTH}px`, height: `${HEIGHT}px` }} onClick={() => onClickPlace(index)}>
                {place.playerName && <Person fontSize="large" htmlColor={color} />}
                {!place.playerName && (
                  <>
                    {!place.doorBarred && <MeetingRoom htmlColor="#fff"/>}
                    {place.doorBarred && <Cancel htmlColor="#fff"/>}
                  </>
                )}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </Box>
  )
};

export default PlayerArea;



