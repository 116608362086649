import { Box, Button, CircularProgress, Container, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import { gameJoin, gameReinit, gameGetState } from '../util/api';

const Home = ({ history }) => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const handleNameChange = event => setName(event.target.value);

  const reinit = async (thingInDeck) => {
    if (!window.confirm('Sure?')) return;
    await gameReinit(thingInDeck);
  };

  const fetchState = async () => {
    const data = await gameGetState();
    setInitialized(_get(data, 'gameState.gameState') !== 'STARTING');
  };

  const join = async () => {
    try {
      setLoading(true);
      const { message, newPlayer } = await gameJoin(name);
      if (message !== 'OK') {
        toast(message || 'Error occured');
      } else {
        history.push(`/${newPlayer.id}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchState, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxWidth="sm" className="container">
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
        {loading && <CircularProgress />}
        {!loading && (
          <>
            {!initialized && <Box m={1} ><TextField className="name-field" placeholder="Input your name" color="primary" value={name} onChange={handleNameChange} /></Box>}
            {!initialized && <Box m={1} ><Button m={2} onClick={join} color="primary" variant="contained" >JOIN</Button></Box>}
            {initialized && <Box m={1} ><Button onClick={() => reinit(true)} color="primary" variant="contained" >REINIT with THING in deck</Button></Box>}
            {initialized && <Box m={1} ><Button onClick={() => reinit(false)} color="primary" variant="contained" >REINIT with THING in hand</Button></Box>}
          </>
        )}
      </Box>
    </Container>
  )
};

export default Home;
