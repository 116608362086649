export const apiUrl = window?.config?.apiUrl || process.env.REACT_APP_API_URL || '';

export const gameGetState = async (playerId) => {
  const response = await fetch(`${apiUrl}/api/state`, { headers: { 'x-player': playerId } });
  const data = await response.json();
  return data;
};

export const gameStart = async (playerId) => {
  const response = await fetch(`${apiUrl}/api/start`, { method: 'post', headers: { 'x-player': playerId } });
  const data = await response.json();
  return data;
};

export const gameAction = async (playerId, actionBody) => {
  const response = await fetch(`${apiUrl}/api/action`, { method: 'post', headers: { 'x-player': playerId, 'Content-Type': 'application/json' }, body: JSON.stringify(actionBody) });
  const data = await response.json();
  return data;
};

export const gameReinit = async (thingInDeck) => {
  const response = await fetch(`${apiUrl}/api/reinit?thingInDeck=${thingInDeck}`, { method: 'post' });
  const data = await response.json();
  return data;
};

export const gameJoin = async (name) => {
  const response = await fetch(`${apiUrl}/api/join`, { method: 'post', body: JSON.stringify({ name }), headers: { 'content-type': 'application/json' } });
  const data = await response.json();
  return data;
};

