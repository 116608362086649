import React from 'react';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import BodyClassName from 'react-body-classname';

import { toast } from 'react-toastify'

import { Box, Button, CircularProgress, Container } from '@material-ui/core';
import PlayingCard from '../components/PlayingCard';
import PlayerArea from '../components/PlayerArea';
import { Link } from 'react-router-dom';
import { gameStart, gameGetState, gameAction } from '../util/api';

const GAME_STATES = {
  STARTING: 'STARTING',
  INITIALIZING: 'INITIALIZING',
  AWAITING_TAKE_CARD: 'AWAITING_TAKE_CARD',
  AWAITING_ACTION: 'AWAITING_ACTION',
  AWAITING_PICK_CARD: 'AWAITING_PICK_CARD',
  AWAITING_DEFEND: 'AWAITING_DEFEND',
  AWAITING_CARD_SWAP_ACTIVE: 'AWAITING_CARD_SWAP_ACTIVE',
  AWAITING_CARD_SWAP_PASSIVE: 'AWAITING_CARD_SWAP_PASSIVE',
  AWAITING_PANIC_STEP: 'AWAITING_PANIC_STEP',
  GAME_OVER_THING_WON: 'GAME_OVER_THING_WON',
  GAME_OVER_PEOPLE_WON: 'GAME_OVER_PEOPLE_WON',
  GAME_OVER_INFECTED_WON: 'GAME_OVER_INFECTED_WON',
};

const ACTION_TYPES = {
  GAME_INITIALIZED: 'GAME_INITIALIZED',
  TURN_NEXT_DONE: 'TURN_NEXT_DONE',
  TAKE_CARD_DONE: 'TAKE_CARD_DONE',
  TURN_ACTION_DONE: 'TURN_ACTION_DONE',
  TURN_PICKED_CARD: 'TURN_PICKED_CARD',
  TURN_DEFEND_DONE: 'TURN_DEFEND_DONE',
  CARD_SWAP_ACTIVE_DONE: 'CARD_SWAP_ACTIVE_DONE',
  CARD_SWAP_PASSIVE_DONE: 'CARD_SWAP_PASSIVE_DONE',
  PANIC_STEP_DONE: 'PANIC_STEP_DONE',
};

const ACTION_SUB_TYPES = {
  PLAY_CARD: 'PLAY_CARD',
  DISCARD_CARD: 'DISCARD_CARD',
  ACCEPT_ACTION: 'ACCEPT_ACTION',
  REJECT_ACTION: 'REJECT_ACTION',
};

const PLAYER_ROLES = {
  THING: 'THING',
  INFECTED: 'INFECTED',
  PERSON: 'PERSON',
};

const jsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
};

const getUnreadMessages = (playerId, messages) => {
  try {
    const readMessages = localStorage.getItem('readMessages');
    const readMessagesObject = jsonParse(readMessages) || {};
    return messages.filter(message => !readMessagesObject[`${playerId}:${message.id}`]);
  } catch {
    return messages;
  }
};


const addReadMessages = (playerId, messageId) => {
  try {
    const readMessages = localStorage.getItem('readMessages');
    const readMessagesObject = jsonParse(readMessages) || {};
    readMessagesObject[`${playerId}:${messageId}`] = true;
    localStorage.setItem('readMessages', JSON.stringify(readMessagesObject));
  } catch (e) {
    console.log(e);
  }
};


class Game extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { game: {} };
    this.interval = null;
    this.handleStart = this.handleStart.bind(this);
    this.handleAccept = this.handleAccept.bind(this);
    this.handlePlayCard = this.handlePlayCard.bind(this);
    this.handleDefend = this.handleDefend.bind(this);
    this.handleSwapCard = this.handleSwapCard.bind(this);
    this.handleDiscardCard = this.handleDiscardCard.bind(this);
    this.handleClickPlace = this.handleClickPlace.bind(this);
    this.handleTakeCard = this.handleTakeCard.bind(this);
    this.handlePickCard = this.handlePickCard.bind(this);
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.fetchState();
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: { params: { playerId } }, history } = this.props;
    const { game: prevGame } = prevState;
    const { game } = this.state;
    const { messages: prevMessages } = prevGame || {};
    const { messages } = game || {};
    const groupedPrevMessages = _groupBy(prevMessages, 'id');
    const newMessages = (messages || []).filter(message => !groupedPrevMessages[message.id]);
    const unreadMessages = getUnreadMessages(playerId, newMessages);

    unreadMessages.forEach((message) => {
      const { cards, text } = message.body || {}
      toast((
        <div>
          <h3>{text}</h3>
          {cards && cards.length && (
            <div>
              {cards.map((card) => (
                <img src={`/static/${card}.png`} width={50} className="card-image" />
              ))}
            </div>
          )}
        </div>
      ), { autoClose: false, onClose: () => addReadMessages(playerId, message.id), closeOnClick: false });
    });

    if (game.unauthorized) {
      history.push('/');
    }
  }

  isStarted() {
    return _get(this.state.game, 'gameState.gameState', null) !== 'STARTING';
  }

  isOver() {
    return [GAME_STATES.GAME_OVER_INFECTED_WON, GAME_STATES.GAME_OVER_PEOPLE_WON, GAME_STATES.GAME_OVER_THING_WON].includes(_get(this.state.game, 'gameState.gameState'));
  }

  isMyTurn() {
    const { match: { params: { playerId } } } = this.props;
    return _get(this.state.game, 'gameState.interactivePlayer', null) === playerId;
  }

  getGameState() {
    return _get(this.state.game, 'gameState.gameState', null);
  }

  getPlayersCount() {
    return (_get(this.state.game, 'allPlayers') || []).length;
  }

  getMyCards() {
    return _get(this.state.game, 'myCards') || [];
  }

  getMyickedCards() {
    return _get(this.state.game, 'myPickedCards') || [];
  }

  getPlaces() {
    return _get(this.state.game, 'places') || [];
  }

  getMyName() {
    return _get(this.state.game, 'name');
  }

  getMyRole() {
    return _get(this.state.game, 'role');
  }

  getActivePlayer() {
    return _get(this.state.game, 'gameState.activePlayer', null);
  }

  getInteractivePlayer() {
    return _get(this.state.game, 'gameState.interactivePlayer', null);
  }

  getSwapPlayerName() {
    return _get(this.state.game, 'swapPlayerName', null);
  }

  async fetchState() {
    const { match: { params: { playerId } } } = this.props;
    const data = await gameGetState(playerId);
    this.setState({ game: data });
  }

  async handleStart() {
    const { match: { params: { playerId } } } = this.props;
    await gameStart(playerId);
  }

  async handleAction(action) {
    this.setState({ cardId: null });
    const { match: { params: { playerId } } } = this.props;
    const { message } = await gameAction(playerId, action);
    if (message !== 'OK') {
      toast(message || 'Error occured');
    }
  }

  handleClickPlace(place) {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    const { cardId } = this.state;
    if (!cardId || !isMyTurn || gameState !== GAME_STATES.AWAITING_ACTION) {
      return;
    }
    this.handleAction({
      type: ACTION_TYPES.TURN_ACTION_DONE,
      payload: {
        action: ACTION_SUB_TYPES.PLAY_CARD,
        cardId,
        place,
      }
    });
  }

  handlePlayCard(cardId) {
    this.setState({ cardId });
  }

  handleDiscardCard(cardId) {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    if (!cardId || !isMyTurn || gameState !== GAME_STATES.AWAITING_ACTION) {
      return;
    }
    this.handleAction({
      type: ACTION_TYPES.TURN_ACTION_DONE,
      payload: {
        action: ACTION_SUB_TYPES.DISCARD_CARD,
        cardId,
      }
    });
  }

  handleSwapCard(cardId) {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    if (!cardId || !isMyTurn) {
      return;
    }
    if (gameState === GAME_STATES.AWAITING_CARD_SWAP_ACTIVE) {
      this.handleAction({
        type: ACTION_TYPES.CARD_SWAP_ACTIVE_DONE,
        payload: {
          cardId,
        }
      });
    }
    if (gameState === GAME_STATES.AWAITING_CARD_SWAP_PASSIVE) {
      this.handleAction({
        type: ACTION_TYPES.CARD_SWAP_PASSIVE_DONE,
        payload: {
          cardId,
          action: ACTION_SUB_TYPES.ACCEPT_ACTION,
        }
      });
    }
  }

  handleDefend(cardId) {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    if (!cardId || !isMyTurn) {
      return;
    }
    if (gameState === GAME_STATES.AWAITING_DEFEND) {
      this.handleAction({
        type: ACTION_TYPES.TURN_DEFEND_DONE,
        payload: {
          cardId,
          action: ACTION_SUB_TYPES.REJECT_ACTION,
        }
      });
    }
    if (gameState === GAME_STATES.AWAITING_CARD_SWAP_PASSIVE) {
      this.handleAction({
        type: ACTION_TYPES.CARD_SWAP_PASSIVE_DONE,
        payload: {
          cardId,
          action: ACTION_SUB_TYPES.REJECT_ACTION,
        }
      });
    }
  }


  handleAccept() {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    if (!isMyTurn) {
      return;
    }
    if (gameState === GAME_STATES.AWAITING_DEFEND) {
      this.handleAction({
        type: ACTION_TYPES.TURN_DEFEND_DONE,
        payload: {
          action: ACTION_SUB_TYPES.ACCEPT_ACTION,
        }
      });
    }
  }

  handleTakeCard() {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    if (!isMyTurn) {
      return;
    }
    if (gameState === GAME_STATES.AWAITING_TAKE_CARD) {
      this.handleAction({
        type: ACTION_TYPES.TAKE_CARD_DONE,
      });
    }
  }

  handlePickCard(cardId) {
    const isMyTurn = this.isMyTurn();
    const gameState = this.getGameState();
    if (!cardId || !isMyTurn) {
      return;
    }
    if (gameState === GAME_STATES.AWAITING_PICK_CARD) {
      this.handleAction({
        type: ACTION_TYPES.TURN_PICKED_CARD,
        payload: {
          cardId,
        }
      });
    }
  }

  render() {
    const { match: { params: { playerId } } } = this.props;
    const gameStarted = this.isStarted();
    const gameOver = this.isOver();
    const myCards = this.getMyCards();
    const myPickedCards = this.getMyickedCards();
    const playersCount = this.getPlayersCount()
    const isMyTurn = this.isMyTurn()
    const places = this.getPlaces();
    const gameState = this.getGameState();
    const activePlayer = this.getActivePlayer();
    const interactivePlayer = this.getInteractivePlayer();
    const role = this.getMyRole();
    const isWaitingForCardPick = gameState === GAME_STATES.AWAITING_PICK_CARD;
    const cards = (isWaitingForCardPick && isMyTurn) ? myPickedCards : myCards;
    const swapPlayerName = this.getSwapPlayerName();
    return (
      <>
        {[PLAYER_ROLES.THING, PLAYER_ROLES.INFECTED].includes(role) && <BodyClassName className="green-screen" />}
        {!gameStarted && (
          <Container maxWidth="sm" className="container">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
              {playersCount < 4 && (
                <>
                <CircularProgress />
                <h2 style={{ color: '#fff', textAlign: 'center'}}>{playersCount} player(s) joined.<br />You need at least 4 people to start</h2>
                </>
              )}
              {playersCount >= 4 && <Button onClick={this.handleStart} variant="contained" color="primary">START</Button>}
            </Box>
          </Container>
        )}
        {gameStarted && !gameOver && (
          <>
            <Box m={1} display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" height="100%">
              <PlayerArea places={places} myPlayerId={playerId} activePlayerId={activePlayer} interactivePlayerId={interactivePlayer} onClickPlace={this.handleClickPlace} />
              {isMyTurn && (
                <h5 style={{ color: '#fff' }}>
                  {gameState === GAME_STATES.AWAITING_TAKE_CARD && 'Your turn! Take a card from deck to start'}
                  {gameState === GAME_STATES.AWAITING_ACTION && 'Your turn! Play or discard a card'}
                  {gameState === GAME_STATES.AWAITING_PICK_CARD && 'Hint: pick one card out of 3'}
                  {gameState === GAME_STATES.AWAITING_DEFEND && 'Someone tries to apply a card on you. It\'s time to defend'}
                  {gameState === GAME_STATES.AWAITING_CARD_SWAP_ACTIVE && role !== PLAYER_ROLES.THING && `It's time to swap with ${swapPlayerName}. Be careful!`}
                  {gameState === GAME_STATES.AWAITING_CARD_SWAP_ACTIVE && role === PLAYER_ROLES.THING && `It's time to swap with ${swapPlayerName}. Are you ready to give infection?`}
                  {gameState === GAME_STATES.AWAITING_CARD_SWAP_PASSIVE && `It's time to swap with ${swapPlayerName}. You can reject it using specific defend cards`}
                </h5>
              )}
              {isMyTurn && gameState === GAME_STATES.AWAITING_DEFEND && <Button variant="contained" color="primary" onClick={this.handleAccept}>ACCEPT</Button>}
              {isMyTurn && gameState === GAME_STATES.AWAITING_TAKE_CARD && <Button variant="contained" color="primary" onClick={this.handleTakeCard}>TAKE CARD</Button>}
              <div className="card-container">
                <Box display="flex" flexDirection="row" justifyContent="center" >
                  {cards.map(({ name, id }) => (
                    <PlayingCard
                      name={name}
                      id={id}
                      showPlay={isMyTurn && gameState === GAME_STATES.AWAITING_ACTION}
                      showDiscard={isMyTurn && gameState === GAME_STATES.AWAITING_ACTION}
                      showSwap={isMyTurn && (gameState === GAME_STATES.AWAITING_CARD_SWAP_ACTIVE || gameState === GAME_STATES.AWAITING_CARD_SWAP_PASSIVE)}
                      showDefend={isMyTurn && (gameState === GAME_STATES.AWAITING_DEFEND || gameState === GAME_STATES.AWAITING_CARD_SWAP_PASSIVE)}
                      showPick={isMyTurn && gameState === GAME_STATES.AWAITING_PICK_CARD}
                      onPlay={this.handlePlayCard}
                      onDiscard={this.handleDiscardCard}
                      onDefend={this.handleDefend}
                      onSwap={this.handleSwapCard}
                      onPick={this.handlePickCard}
                      selected={this.state.cardId === id}
                    />
                  ))}
                </Box>
              </div>
            </Box>
          </>
        )}
        {gameOver && (
          <Container maxWidth="sm" className="container">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
              {gameState === GAME_STATES.GAME_OVER_THING_WON && <h1 style={{ color: 'green' }}>Game Over: THING WON</h1>}
              {gameState === GAME_STATES.GAME_OVER_INFECTED_WON && <h1 style={{ color: 'green' }}>Game Over: INFECTED WON</h1>}
              {gameState === GAME_STATES.GAME_OVER_PEOPLE_WON && <h1 style={{ color: '#fff' }}>Game Over: PEOPLE WON</h1>}
              <Link to="/"><Button variant="contained">Back to main page</Button></Link>
            </Box>
          </Container>
        )}
      </>
    );
  }
}

export default Game;
