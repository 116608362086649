import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Game, Home } from './routes';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const App = () => (
  <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/:playerId" component={Game} />
      </Switch>
    </BrowserRouter>
    <ToastContainer newestOnTop />
  </>
);

export default App;
